import React from "react";
import { Loader2 } from "lucide-react";

const HeroSection = ({ currentUser, tokensHeld, tokensFetched }) => {
  return (
    <div className="relative w-full flex justify-center items-center">
      {/* Center content */}
      <div className="relative z-10 flex flex-col items-center justify-center text-center text-white px-4 pt-4 py-4">
        <img src="/assets/bigLogo.PNG" alt="Logo" className="mb-4 w-64 z-0" />
        <a
          href="https://openfund.com/trade/SharkGang"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-full">
            Buy/Sell SharkGang Token
          </button>
        </a>
        {currentUser && (
          <p className="mb-6 flex items-center">
            Tokens Held:
            {tokensFetched ? (
              <span className="ml-1">{tokensHeld}</span>
            ) : (
              <span className="inline-flex items-center ml-2">
                <Loader2
                  className="animate-spin"
                  size={16}
                  style={{ verticalAlign: "middle" }}
                />
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default HeroSection;