import { DeSoIdentityProvider } from "react-deso-protocol";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Root } from "./routes/root";
import React, { useState } from "react";
import userPrefsStore from 'context/userPrefsStore';
import HomeTwo from "routes/home-two";




const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <HomeTwo /> },
    ],
  },
]);

function App() {
  const [userPrefs, setUserPrefs] = useState(null);

  return (
    <React.StrictMode>
      <DeSoIdentityProvider>
        <userPrefsStore.Provider value={{ userPrefs, setUserPrefs }}>
          <RouterProvider router={router}>
          </RouterProvider>
        </userPrefsStore.Provider>
      </DeSoIdentityProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);